import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.19.4_next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom_cekopxraoedc6fnbpt76z34uj4/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeaderLinks"] */ "/vercel/path0/src/components/common/page-header-links.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/page-header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/error.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkButton"] */ "/vercel/path0/src/components/ui/link-button.tsx");
